import Accordion from 'src/components/Accordion/Accordion';
import classNames from 'classnames';
import styles from './seo-content.module.scss';

export interface SeoContentProps {
    title: string;
    children: React.ReactNode;
}

const SeoContent = ({ title, children }: SeoContentProps) => {
    const AccordionItem = {
        title: title,
        content: children,
    };
    return (
        <div className={classNames(styles['seo-content'])}>
            <Accordion items={[AccordionItem]} />
        </div>
    );
};

export default SeoContent;
