import classNames from 'classnames';
import Heading from '../Heading/Heading';
import Image from 'next/image';

export type HowItWorksProps = {
    data: HowItWorksItemType[];
    className?: string;
};

type HowItWorksItemType = {
    title: string;
    headingType?: 'alternative' | 'normal' | 'heading';
    description: string;
    image: {
        src: string;
        height: number;
        width: number;
        className?: string;
    };
};

const HowItWorks = ({ data, className }: HowItWorksProps) => {
    return (
        <div
            className={classNames(
                'container mx-auto xl:max-w-screen-xl',
                className,
            )}
        >
            <Heading level={'h2'} tag={'h'} centered={true} className={'mb-2'}>
                Mosh Makes It Simple
            </Heading>
            <p className="mx-auto mb-8 text-center">
                100% online. Convenient. Discreet.
            </p>
            <div className="flex flex-wrap justify-center md:flex-nowrap md:space-x-8 xl:space-x-12">
                {data.map((item, i) => {
                    return (
                        <div
                            className="mb-4 w-full text-center md:mb-0 md:w-1/3"
                            key={i}
                        >
                            <picture className="mb-4 block w-full text-center">
                                <Image
                                    src={item.image.src}
                                    width={item.image.width}
                                    height={item.image.height}
                                    className={classNames(
                                        'mx-auto w-[180px] sm:w-[140px] md:max-w-[160px]',
                                        item.image.className,
                                    )}
                                    alt={item.title || ''}
                                />
                            </picture>
                            <p className="pb-2 text-md tracking-tight">
                                <span
                                    className={classNames(
                                        item.headingType === 'alternative'
                                            ? 'font-demi'
                                            : item.headingType === 'normal'
                                            ? 'font-body'
                                            : 'font-heading',
                                    )}
                                >
                                    {item.title}
                                </span>
                            </p>
                            <p className="pb-4">{item.description}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HowItWorks;
