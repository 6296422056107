'use client';

import { useState } from 'react';

import AccordionItem, {
    AccordionItemProps,
} from '../AccordionItem/AccordionItem';
import { trackClick } from 'src/analytics/Events';
import EventNames from 'src/analytics/EventNames';

type AccordionProps = {
    items: AccordionItemProps[];
    openMultiple?: boolean;
    openItemOnStart?: number;
    isTitleBolded?: boolean;
    className?: string;
    itemClassName?: string;
    border?: boolean;
};

const Accordion = ({
    items = [],
    openMultiple = false,
    openItemOnStart = -1,
    isTitleBolded = false,
    className = '',
    itemClassName,
    border = true,
}: AccordionProps) => {
    const [selectedItems, setSelectedItems] = useState<number[]>([
        openItemOnStart,
    ]);

    const toggleAccordionItem = (selectedItem: number, title: string) => {
        const isItemSelected = selectedItems.includes(selectedItem);

        if (isItemSelected) {
            // remove selected item
            setSelectedItems(selectedItems.filter((id) => id !== selectedItem));
        } else {
            // track only when expanding
            trackClick(EventNames.ACCORDION_EXPANDED, {
                item: selectedItem + 1,
                title,
            });
            if (openMultiple) {
                // add item to selected items
                setSelectedItems((selectedItems) => [
                    ...selectedItems,
                    selectedItem,
                ]);
            } else {
                // clear items and add a selected one
                const clickedItems: number[] = [];
                clickedItems.push(selectedItem);
                setSelectedItems(clickedItems);
            }
        }
    };
    return (
        <div
            className={className}
            data-accordion
            data-accordion-border={border}
        >
            {items.map((item, id) => (
                <AccordionItem
                    key={id}
                    icon={item.icon}
                    title={item.title}
                    subtitle={item.subtitle}
                    content={item.content}
                    isActive={selectedItems.includes(id)}
                    isTitleBolded={isTitleBolded}
                    onClick={() => toggleAccordionItem(id, item.title)}
                    className={itemClassName}
                />
            ))}
        </div>
    );
};

export default Accordion;
