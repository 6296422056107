import { faAngleDown, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import HTMLReactParser from 'html-react-parser';
import Image from 'next/image';
import { IconProps } from 'src/types/Image';
import styles from './accordion-item.module.scss';

export type AccordionItemProps = {
    title: string;
    subtitle?: string;
    isActive?: boolean;
    isTitleBolded?: boolean;
    onClick?: () => void;
    content: React.ReactNode | string;
    icon?: IconProps;
    className?: string;
};

const AccordionItem = ({
    title,
    subtitle,
    isActive = false,
    isTitleBolded,
    onClick,
    content,
    icon,
    className,
}: AccordionItemProps) => {
    const iconExpandCollapse = isActive ? faXmark : faAngleDown;
    return (
        <div className={classNames(styles['accordion__item'], className)}>
            <button
                className={classNames(
                    styles['accordion__button'],
                    isTitleBolded && styles['bolded'],
                )}
                onClick={onClick}
                data-accordion-button
            >
                <div className={styles['accordion__title']}>
                    {icon && (
                        <Image
                            src={icon.src}
                            width={icon.width}
                            height={icon.height}
                            alt={title}
                            className={styles['accordion__icon']}
                        />
                    )}
                    <p>
                        {title}
                        {subtitle && (
                            <small className={styles['accordion__subtitle']}>
                                {subtitle}
                            </small>
                        )}
                    </p>
                </div>

                <FontAwesomeIcon
                    icon={iconExpandCollapse}
                    className={'h-4 w-4'}
                />
            </button>
            <div
                className={classNames(
                    styles['accordion__content'],
                    isActive ? styles['expanded'] : styles['collapsed'],
                )}
                data-accordion-content-expanded={isActive}
                role="region"
            >
                {typeof content === 'string'
                    ? HTMLReactParser(content)
                    : content}
            </div>
        </div>
    );
};

export default AccordionItem;
